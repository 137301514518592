import { useVehicleStatusQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { Card, Divider } from '@cooltra/ui';
import { isBike } from '@cooltra/vehicle-utils';
import { FormattedMessage } from 'react-intl';

import { ActionButtons } from '~/common';
import { useVehicleQuery } from '~/hooks';

import * as Items from '../Items';

import messages from './messages';

export type TelematicsInformationProps = {
  vehicleId: string;
};

export const TelematicsInformation = ({
  vehicleId,
}: TelematicsInformationProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { data: vehicle } = useVehicleQuery(vehicleId);
  const { data: vehicleStatus, ...vehicleStatusQuery } = useVehicleStatusQuery(
    vehicleId,
    {
      retry: false,
    }
  );

  const fieldProps = {
    vehicleStatus,
    error: vehicleStatusQuery.error,
  };

  const userCanSeeMotorcycleTelematicsActionsButtons =
    hasPermission('action:vehicles') && vehicle && !isBike(vehicle);

  const userCanSeeBikeTelematicsActionsButtons =
    hasPermission('action:vehicles') && vehicle && isBike(vehicle);

  const isNiuBike = vehicle?.telematics === 'NIU';

  return (
    <div data-testid="TELEMATICS_INFORMATION_CONTENT">
      <div className="h-10 flex items-center mb-6 justify-between">
        <div className="flex items-center">
          <h2 className="text-base text-neutral-500 font-semibold">
            <FormattedMessage {...messages.telematics} />
          </h2>
          <Items.Connection
            {...fieldProps}
            isLoading={vehicleStatusQuery.isLoading}
          />
        </div>
        <ActionButtons.Sync vehicleId={vehicleId} emphasis="high" />
      </div>
      <Card>
        <div className="p-8 pb-6">
          <ul>
            <Items.LastSync {...fieldProps} telematics={vehicle?.telematics} />
            <Divider />
            <Items.Power {...fieldProps} />
            <Divider />
            <Items.TelematicsBattery {...fieldProps} />
            <Divider />
            {vehicle && vehicle.telematics == 'AXA' && (
              <>
                <Items.BoardVoltage {...fieldProps} />
                <Divider />
              </>
            )}
            <Items.LockStatus {...fieldProps} />
            {vehicle && vehicle.telematics !== 'AXA' && (
              <>
                <Divider />
                <Items.CentralStand {...fieldProps} />
                <Divider />
                <Items.Helmet {...fieldProps} />
              </>
            )}
            {vehicle && vehicle.telematics !== 'AXA' && (
              <>
                <Divider />
                <Items.TopCase {...fieldProps} />
              </>
            )}
            {vehicle && vehicle.telematics == 'NIU' && (
              <>
                <Divider />
                <Items.TopCaseLock {...fieldProps} />
                <Divider />
                <Items.Saddle {...fieldProps} />
                <Divider />
                <Items.Alarm {...fieldProps} />
              </>
            )}
          </ul>
          <div className="flex flex-wrap mt-4 -m-1">
            {userCanSeeMotorcycleTelematicsActionsButtons && (
              <>
                <div className="w-1/2 p-1">
                  <ActionButtons.Honk vehicleId={vehicleId} showIcon={true} />
                </div>
                <div className="w-1/2 p-1">
                  <ActionButtons.UnlockTopCase
                    vehicleId={vehicleId}
                    showIcon={true}
                  />
                </div>
                <div className="w-1/2 p-1">
                  <ActionButtons.Lock vehicleId={vehicleId} showIcon={true} />
                </div>
                <div className="w-1/2 p-1">
                  <ActionButtons.Unlock vehicleId={vehicleId} showIcon={true} />
                </div>
                {isNiuBike && (
                  <>
                    <div className="w-1/2 p-1">
                      <ActionButtons.GpsReboot vehicleId={vehicleId} />
                    </div>
                    <div className="w-1/2 p-1">
                      <ActionButtons.OpenSaddle vehicleId={vehicleId} />
                    </div>
                    <div className="w-1/2 p-1">
                      <ActionButtons.IgnitionOn vehicleId={vehicleId} />
                    </div>
                    <div className="w-1/2 p-1">
                      <ActionButtons.IgnitionOff vehicleId={vehicleId} />
                    </div>
                    <div className="w-1/2 p-1">
                      <ActionButtons.EnableAlarm vehicleId={vehicleId} />
                    </div>
                    <div className="w-1/2 p-1">
                      <ActionButtons.DisableAlarm vehicleId={vehicleId} />
                    </div>
                  </>
                )}
              </>
            )}
            {userCanSeeBikeTelematicsActionsButtons && (
              <>
                <div className="w-1/2 p-1">
                  <ActionButtons.Lock vehicleId={vehicleId} showIcon={true} />
                </div>
                <div className="w-1/2 p-1">
                  <ActionButtons.Unlock vehicleId={vehicleId} showIcon={true} />
                </div>
                <div className="w-1/2 p-1">
                  <ActionButtons.PowerOff
                    vehicleId={vehicleId}
                    showIcon={true}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};
