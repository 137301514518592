import { UpdatePromotionPayload } from '@cooltra/api';

import {
  formatCodeValues,
  formatDiscountAndCreditValues,
  formatPromotionDates,
  formatPromotionTranslations,
  PromotionFormValues,
} from '~/common';

export const getEditPromotionPayload = (
  values: PromotionFormValues
): UpdatePromotionPayload => {
  const dates = formatPromotionDates(values);
  const { code } = formatCodeValues(values);
  const discountAndCreditValues = formatDiscountAndCreditValues(values);
  const translations = formatPromotionTranslations(values);

  return {
    about: values.about,
    code,
    maxUses: values.maxUses ? Number(values.maxUses) : null,
    registration: values.registration,
    userCanRedeemMoreThanOneCode: values.userCanRedeemMoreThanOneCode,
    targetHomeSystems: values.targetHomeSystems,
    targetVehicleModels: values.targetVehicleModels,
    ...dates,
    ...discountAndCreditValues,
    ...translations,
  };
};
