import {
  OperationalCondition,
  OperationalState,
  VehicleListItem,
} from '@cooltra/api';

import { BackgroundCircle } from './BackgroundCircle';
import { Battery } from './Battery';
import { Bike } from './Bike';
import { Motorbike } from './Motorbike';
import { Star } from './Star';

export type VehicleIconProps = Pick<
  VehicleListItem,
  'bonusEnabled' | 'model'
> & {
  operationalState: OperationalState | null | undefined;
  operationalConditions: OperationalCondition[] | null | undefined;
  batteryCharge: number | null | undefined;
  size?: number;
};

export const VehicleIcon = ({
  operationalConditions,
  operationalState,
  bonusEnabled,
  model,
  batteryCharge,
  size,
}: VehicleIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox={`0 0 144 144`}
  >
    <BackgroundCircle
      operationalState={operationalState}
      operationalConditions={operationalConditions}
    />
    <Battery batteryCharge={batteryCharge} />
    {model === 'ARCADE_X2C' ? <Bike /> : <Motorbike />}
    {bonusEnabled && <Star />}
  </svg>
);
