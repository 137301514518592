import { Checkbox, Icon, ProgressBar, Tooltip } from '@cooltra/ui';
import { forwardRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { VehicleListItem } from '@cooltra/api';
import { RouterBaseLink, RouterCardLink } from '@cooltra/navigation';
import { MdCheck } from 'react-icons/md';

import { ModelImage, OperationalStateStatusPoint } from '~/common';

import { VehicleRowLayout } from '../../VehicleRowLayout/VehicleRowLayout';
import { VehicleTooltip } from '../../VehicleTooltip/VehicleTooltip';

import messages from './messages';
import { Location } from './Location';
import { Tasks } from './Tasks';
import { TakenBy } from './TakenBy';
import { WorkUnitBy } from './WorkUnitBy';
import { Services } from './Services';

export type VehicleCardProps = {
  vehicle: VehicleListItem;
  onCheck: (vehicle: VehicleListItem) => void;
  checked: boolean;
};

export const VehicleCard = forwardRef<HTMLDivElement, VehicleCardProps>(
  ({ vehicle, onCheck, checked }, ref) => {
    const {
      vehicleId,
      identificationNumber,
      model,
      licensePlate,
      externalId,
      telematics,
      cityHallPublished,
      locationId,
      inLocationFrom,
      takenBy,
      workUnitBy,
      openTasks,
      status,
      services,
      technicalInspections,
    } = vehicle;

    const handleCheck = useCallback(() => {
      onCheck(vehicle);
    }, [onCheck, vehicle]);

    const blockingTasks = openTasks.filter(({ blocking }) => blocking);

    const getLocation = useCallback(() => {
      if (workUnitBy) {
        return <WorkUnitBy workUnitBy={workUnitBy} />;
      }

      if (takenBy) {
        return <TakenBy takenBy={takenBy} />;
      }

      return (
        <Location locationId={locationId} inLocationFrom={inLocationFrom} />
      );
    }, [locationId, takenBy, workUnitBy, inLocationFrom]);

    const modelSuffix = () => {
      if (model === 'NIU_N1S') return ' | 45 km/h';
      else if (model === 'NIU_N1S_25') return ' | 25 km/h';
      else return '';
    };

    return (
      <RouterCardLink isMultiAction to={`/vehicles/${vehicleId}`}>
        <VehicleRowLayout
          checkbox={
            <label
              className="relative hover:cursor-pointer"
              htmlFor={`input-${vehicleId}`}
            >
              <span className="flex sr-only">
                <FormattedMessage {...messages.selectVehicle} />
              </span>
              <Checkbox
                id={`input-${vehicleId}`}
                checked={checked}
                onChange={handleCheck}
              />
            </label>
          }
          info={
            <div className="h-24 w-full flex items-center justify-start">
              <Tooltip
                button={
                  <div
                    ref={ref}
                    className="flex gap-4 items-center justify-between"
                  >
                    <OperationalStateStatusPoint
                      operationalState={vehicle.operationalState}
                    />
                    <RouterBaseLink
                      to={`/vehicles/${vehicleId}`}
                      className="flex flex-col items-center shrink-0"
                    >
                      <ModelImage
                        model={model}
                        identificationNumber={identificationNumber}
                        className="w-16"
                      />
                      {status?.batteryCharge && (
                        <div className="h-1.5 w-9 mt-1">
                          <ProgressBar value={status?.batteryCharge.value} />
                        </div>
                      )}
                    </RouterBaseLink>
                  </div>
                }
                content={
                  <VehicleTooltip
                    model={model}
                    identificationNumber={identificationNumber}
                    telematics={telematics}
                    cityHallPublished={cityHallPublished}
                    status={status}
                  />
                }
              />
              <div className="pl-4 text-xs text-neutral-900 text-left">
                <span className="block font-semibold mb-1">{externalId}</span>
                <div className="flex items-center">
                  <span>
                    {licensePlate}
                    {modelSuffix()}
                  </span>
                  {cityHallPublished && (
                    <Icon className="ml-3 text-neutral-400 bg-neutral-50 w-5 h-4 rounded inline-block">
                      <MdCheck />
                    </Icon>
                  )}
                </div>
              </div>
            </div>
          }
          tasks={
            <Tasks
              openTasksNumber={openTasks.length}
              blockingTasksNumber={blockingTasks.length}
            />
          }
          location={getLocation()}
          services={
            <Services
              services={services}
              technicalInspections={technicalInspections}
            />
          }
        />
      </RouterCardLink>
    );
  }
);
