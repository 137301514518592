import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { UserInputSearch } from './UsersInputSearch/UserInputSearch';
import messages from './messages';

export const UsersSearch = () => (
  <Card className="pt-16 pb-20 text-center">
    <h1 className="font-semibold text-4xl">
      <FormattedMessage {...messages.searchUsers} />
    </h1>
    <UserInputSearch className="w-80 mt-10 mx-auto text-left" />
  </Card>
);
