import { defineMessages } from 'react-intl';

export default defineMessages({
  createdAtAscending: {
    defaultMessage: 'Oldest first',
  },
  createdAtDescending: {
    defaultMessage: 'Newest first',
  },
});
