import { LanguageCode } from '@cooltra/countries';
import { Model } from '@cooltra/api';
import { System } from '@cooltra/auth-api';

import { today } from '~/utils/date';

import { PromotionStateType } from '../PromotionState/PromotionState';

type TitleTranslationKey = `titleTranslations.${LanguageCode}`;
type TitleTranslations = Record<TitleTranslationKey, string>;

type DescriptionTranslationKey = `descriptionTranslations.${LanguageCode}`;
type DescriptionTranslations = Record<DescriptionTranslationKey, string>;

export type PromotionCapType = 'rentals' | 'total';
export type PromotionTypeMode = 'credit' | 'discount';
export type PromotionCodeMode = 'single-code' | 'multi-code';
export type BenefitExpirationRangeMode =
  | 'specific-date'
  | 'after-days'
  | 'after-hours';

export type PromotionFormValues = {
  promotionTypeMode: PromotionTypeMode;
  code: string;
  promotionCodeMode: PromotionCodeMode;
  codePrefix: string;
  numberOfCodes: number | '';
  'credit.value': number | '';
  capType: PromotionCapType;
  discount: number | '';
  'discountCap.value': number | '';
  rentalsCap: number | '';
  'discountCapPerRental.value': number | '';
  about: string;
  validFromDate: string;
  validFromTime: string;
  validUntilDate: string;
  validUntilTime: string;
  promotionDoesntExpire: boolean;
  benefitFromDate: string;
  benefitFromTime: string;
  benefitUntilDate: string;
  benefitUntilTime: string;
  benefitDoesntExpire: boolean;
  benefitExpiresAfterDays: number | '';
  benefitExpiresAfterHours: number | '';
  benefitExpirationRangeMode: BenefitExpirationRangeMode;
  maxUses: number | '';
  registration: boolean;
  editMode: boolean;
  userCanRedeemMoreThanOneCode: boolean;
  stateType: PromotionStateType | null;
  targetHomeSystems: System[];
  targetVehicleModels: Model[];
} & TitleTranslations &
  DescriptionTranslations;

export const promotionInitialFormValues: PromotionFormValues = {
  promotionTypeMode: 'credit',
  code: '',
  promotionCodeMode: 'single-code',
  codePrefix: '',
  numberOfCodes: '',
  userCanRedeemMoreThanOneCode: false,
  'credit.value': '',
  capType: 'rentals',
  discount: '',
  'discountCap.value': '',
  rentalsCap: '',
  'discountCapPerRental.value': '',
  about: '',
  validFromDate: today,
  validFromTime: '00:00',
  validUntilDate: '',
  validUntilTime: '23:59',
  promotionDoesntExpire: false,
  benefitFromDate: '',
  benefitFromTime: '',
  benefitUntilDate: '',
  benefitUntilTime: '',
  benefitDoesntExpire: false,
  benefitExpiresAfterDays: '',
  benefitExpiresAfterHours: '',
  benefitExpirationRangeMode: 'specific-date',
  maxUses: '',
  registration: false,
  'titleTranslations.en': '',
  'titleTranslations.es': '',
  'titleTranslations.it': '',
  'titleTranslations.pt': '',
  'titleTranslations.fr': '',
  'titleTranslations.ca': '',
  'titleTranslations.nl': '',
  'descriptionTranslations.en': '',
  'descriptionTranslations.es': '',
  'descriptionTranslations.it': '',
  'descriptionTranslations.pt': '',
  'descriptionTranslations.fr': '',
  'descriptionTranslations.ca': '',
  'descriptionTranslations.nl': '',
  editMode: false,
  stateType: null,
  targetHomeSystems: [],
  targetVehicleModels: [],
};
