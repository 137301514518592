import { Expression } from 'mapbox-gl';

export const getCircleColorExpressionByBatteryState = (): Expression => [
  'step',
  ['get', 'battery'],
  '#E12D39',
  15,
  '#F0B428',
  30,
  '#764B23',
  45,
  '#189A1C',
];
