import { useIntl } from 'react-intl';

import { SortField } from '~/common';

import messages from './messages';

const options = [
  {
    label: messages.updatedAtAscending,
    value: 'updatedAt.asc',
  },
  {
    label: messages.updatedAtDescending,
    value: 'updatedAt.desc',
  },
];

export const Sort = () => {
  const { formatMessage } = useIntl();

  const translatedOptions = options.map(({ label, value }) => ({
    label: formatMessage(label),
    value,
  }));

  return <SortField options={translatedOptions} />;
};
