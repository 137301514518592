import {
  getBatteryStatus,
  VehicleBatteryStatus,
  VehicleFeatureBuilderProps,
  VehicleFeatureImageProps,
  VehicleImageType,
} from '../../../tools';

type BatteryMapVehicleImageName =
  `${VehicleImageType}-BATTERY_STATE-${VehicleBatteryStatus}`;

type BatteryMapBonusVehicleImageName =
  `BONUS-${VehicleImageType}-BATTERY_STATE-${VehicleBatteryStatus}`;

type BatteryMapVehicleWithTasksImageName =
  `${VehicleImageType}-BATTERY_STATE-${VehicleBatteryStatus}-WITH-OPEN-TASKS`;

type BatteryMapBonusVehicleWithTasksImageName =
  `BONUS-${VehicleImageType}-BATTERY_STATE-${VehicleBatteryStatus}-WITH-OPEN-TASKS`;

type BatteryMapImageName =
  | BatteryMapVehicleImageName
  | BatteryMapBonusVehicleImageName
  | BatteryMapVehicleWithTasksImageName
  | BatteryMapBonusVehicleWithTasksImageName;

export class BatteryLevelVehicleFeatureImage
  implements VehicleFeatureImageProps
{
  getImageName = ({
    isABike,
    numberOfOpenTasks,
    battery,
    bonusEnabled,
  }: VehicleFeatureBuilderProps): BatteryMapImageName => {
    const bonus = bonusEnabled ? 'BONUS-' : '';
    const vehicle = isABike ? 'BIKE' : 'MOTORCYCLE';
    const withOpenTasks = numberOfOpenTasks > 0 ? '-WITH-OPEN-TASKS' : '';
    const batteryState = getBatteryStatus(battery);

    return `${bonus}${vehicle}-BATTERY_STATE-${batteryState}${withOpenTasks}`;
  };
}
