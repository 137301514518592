import { Divider, InfiniteList } from '@cooltra/ui';
import { VehicleListItem } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { classNames } from '@cooltra/utils';

import {
  ErrorPage,
  Four0Three,
  NoSearchResults,
  VehicleFilterFields,
  VehicleFiltersModal,
} from '~/common';
import {
  useFilteredVehiclesQuery,
  useVehiclesFilters,
} from '~/libs/vehicles-filters';
import { useNavigateWithQueryParams } from '~/hooks';

import { VehiclesTotal } from '../VehiclesTotal/VehiclesTotal';
import { VehicleCard, VehicleCardLoading } from '../VehicleCard';
import { VehiclesViews } from '../VehiclesViews/VehiclesViews';
import { VehiclesSearch } from '../VehiclesSearch/VehiclesSearch';
import { VehiclesListHeader } from '../VehiclesListHeader/VehiclesListHeader';
import { FloatingMenu } from '../FloatingMenu/FloatingMenu';

const pageSize = 20;

export const VehiclesList = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { user } = useAuth0();

  const navigateWithQueryParams = useNavigateWithQueryParams();
  const location = useLocation();

  const [page, setPage] = useState(1);

  const fetchMore = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, []);

  const [vehiclesState, setVehiclesState] = useState<{
    allVehiclesChecked: boolean;
    selectedVehicles: VehicleListItem[];
  }>({
    allVehiclesChecked: false,
    selectedVehicles: [],
  });
  const { values } = useVehiclesFilters();

  useEffect(() => {
    navigateWithQueryParams(values);
    setVehiclesState({
      allVehiclesChecked: false,
      selectedVehicles: [],
    });
  }, [location.pathname, navigateWithQueryParams, values]);

  const {
    isLoading,
    isError,
    error,
    refetch,
    data: { filteredVehicles },
  } = useFilteredVehiclesQuery();

  const { selectedVehicles } = vehiclesState;

  const data = useMemo(
    () => filteredVehicles.slice(0, pageSize * page),
    [filteredVehicles, page]
  );

  const noVehiclesFound = !isLoading && !filteredVehicles.length;

  const isVehicleSelected = useCallback(
    (vehicle: VehicleListItem) =>
      !!selectedVehicles.find(
        ({ vehicleId }) => vehicle.vehicleId === vehicleId
      ),
    [selectedVehicles]
  );

  const filteredSelection = useMemo(
    () => filteredVehicles.filter(isVehicleSelected),
    [isVehicleSelected, filteredVehicles]
  );

  const selectedVehiclesSystems = useMemo(
    () => [...new Set(filteredSelection.map(({ system }) => system))],
    [filteredSelection]
  );

  const selectedVehiclesIds = useMemo(
    () => filteredSelection.map(({ vehicleId }) => vehicleId),
    [filteredSelection]
  );

  const selectAllVehicles = (checked: boolean) => {
    if (filteredVehicles) {
      setVehiclesState({
        allVehiclesChecked: checked,
        selectedVehicles: checked ? filteredVehicles : [],
      });
    }
  };

  const selectVehicle = useCallback(
    (vehicle: VehicleListItem) =>
      setVehiclesState(({ selectedVehicles }) => {
        const newSelectedVehicles = isVehicleSelected(vehicle)
          ? selectedVehicles.filter(
              ({ vehicleId }) => vehicleId !== vehicle.vehicleId
            )
          : selectedVehicles.concat(vehicle);

        return {
          allVehiclesChecked:
            filteredVehicles.length === newSelectedVehicles.length,
          selectedVehicles: newSelectedVehicles,
        };
      }),
    [filteredVehicles.length, isVehicleSelected]
  );

  const areMyTakenVehiclesSelected = filteredSelection.some(
    ({ takenBy }) => !!takenBy && takenBy === user?.sub
  );

  const canVehicleBeTransferred =
    selectedVehiclesSystems.length > 0 &&
    !filteredSelection.some(
      ({ takenBy }) => !!takenBy && takenBy !== user?.sub
    );

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const canSeeFloatingMenu =
    hasPermission('export:vehicles') || hasPermission('bulk:actions');

  return (
    <>
      <div
        className={classNames(
          'container min-w-3xl max-w-6xl py-12',
          canSeeFloatingMenu && 'pb-48'
        )}
      >
        <VehiclesViews />
        <div className="mb-10">
          <div className="flex justify-between items-center mb-10">
            <div className="flex gap-6">
              <VehicleFiltersModal disabled={isLoading} />
              <VehiclesSearch />
            </div>
            <VehicleFilterFields.Sort />
          </div>
          <Divider className="mb-8" />
          <VehiclesTotal
            loading={isLoading}
            selected={filteredSelection.length}
            total={filteredVehicles.length}
          />
        </div>
        <InfiniteList
          isLoading={isLoading}
          isFetchingNextPage={false}
          fetchMore={fetchMore}
          idProp="vehicleId"
          data={data}
          renderEmptyMessage={<NoSearchResults />}
          renderHeader={
            <VehiclesListHeader
              onCheck={selectAllVehicles}
              checked={vehiclesState.allVehiclesChecked}
            />
          }
          renderLoadingRow={<VehicleCardLoading />}
          renderRow={(vehicle) => (
            <VehicleCard
              checked={isVehicleSelected(vehicle)}
              onCheck={selectVehicle}
              vehicle={vehicle}
            />
          )}
        />
      </div>
      {canSeeFloatingMenu && (
        <div className="w-full fixed bottom-0 bg-neutral-0 border-t border-neutral-50 shadow-2xl">
          <div className="container min-w-3xl max-w-6xl py-8">
            <FloatingMenu
              systems={selectedVehiclesSystems}
              refetchVehicles={refetch}
              selectedVehiclesIds={selectedVehiclesIds}
              noVehiclesFound={noVehiclesFound}
              canVehicleBeTransferred={canVehicleBeTransferred}
              areMyTakenVehiclesSelected={areMyTakenVehiclesSelected}
            />
          </div>
        </div>
      )}
    </>
  );
};
