import { FormattedMessage, useIntl } from 'react-intl';
import { Vehicle, useHonkMutation } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { Button, ButtonProps } from '@cooltra/ui';
import { AxiosError } from 'axios';
import { MdVolumeUp } from 'react-icons/md';

import { useNotification } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import { errorMessages } from '../error-messages';

import messages from './messages';

export type HonkProps = Pick<Vehicle, 'vehicleId'> &
  Pick<ButtonProps, 'emphasis'> & { showIcon: boolean };

export const Honk = ({ vehicleId, emphasis, showIcon }: HonkProps) => {
  const { formatMessage } = useIntl();
  const { activatePollingVehicle } = useVehiclePolling();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleOnSuccess = () => {
    addSuccessNotification(formatMessage(messages.successOnHonkVehicle));
    activatePollingVehicle(vehicleId);
  };

  const handleOnError = (error: AxiosError) => {
    let errorNotification;

    switch (getErrorStatus(error)) {
      case 409:
        errorNotification = formatMessage(errorMessages.conflictInAction);
        break;
      case 422:
        errorNotification = formatMessage(
          errorMessages.notProperlyConfiguredVehicle
        );
        break;
      case 502:
        errorNotification = formatMessage(
          errorMessages.issueWithTelematicsProvider
        );
        break;
      case 504:
        errorNotification = formatMessage(errorMessages.vehicleIsOffline);
        break;
    }

    addErrorNotification(errorNotification);
  };

  const { isPending, mutate } = useHonkMutation(vehicleId, {
    onError: handleOnError,
    onSuccess: handleOnSuccess,
  });

  return (
    <Button
      size={showIcon ? 'lg' : 'sm'}
      emphasis={emphasis}
      loading={isPending}
      onClick={() => mutate()}
      className={showIcon ? 'w-full' : ''}
    >
      <div className="flex flex-col items-center justify-center">
        {showIcon && <MdVolumeUp className="w-5 h-5 mb-1 mt-1" />}
        <span className="text-sm">
          <FormattedMessage {...messages.honk} />
        </span>
      </div>
    </Button>
  );
};
