import { LayerProps } from 'react-map-gl';

import { getCircleColorExpressionByBatteryState } from './layers-utils';

export const singleVehiclePointLayer: LayerProps = {
  id: 'single-vehicle-point',
  source: 'high-view-of-batteries-single-vehicles',
  type: 'circle',
  layout: {
    'circle-sort-key': 2,
  },
  paint: {
    'circle-color': getCircleColorExpressionByBatteryState(),
    'circle-radius': 7,
    'circle-stroke-width': 0,
    'circle-stroke-color': '#fff',
  },
};

export const singleSelectedVehiclePointLayer: LayerProps = {
  id: 'single-selected-vehicle-point',
  source: 'high-view-of-batteries-single-vehicles',
  type: 'circle',
  filter: ['all', ['==', ['get', 'isSelected'], true]],
  layout: {
    'circle-sort-key': 1,
  },
  paint: {
    'circle-color': '#fff',
    'circle-radius': 10,
    'circle-stroke-width': 2,
    'circle-stroke-color': getCircleColorExpressionByBatteryState(),
  },
};
