import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormattedMessage } from 'react-intl';
import { RouterTab } from '@cooltra/navigation';
import { stringifyParams } from '@cooltra/utils';

import messages from './messages';

export const OffersNavigationTabs = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const canSeePromotionsList = hasPermission('read:offers');
  const canSeeCooltraForEmployeesList = hasPermission(
    'read:cooltra_for_employees'
  );
  const canSeeStationBasedPromotionsList = hasPermission(
    'read:station_based_offers'
  );
  const defaultPromotionsParams = {
    keyword: '',
    state: '',
    sort: 'createdAt.desc',
  };
  const params = stringifyParams(defaultPromotionsParams);
  const promotionRouterTabUrl = `/offers/promotions?${params}`;
  const cooltraForEmployeesRouterTabUrl = `/offers/cooltra-for-employees?${params}`;
  const cooltraStationBasedRouterTabUrl = `/offers/station-based`;

  return (
    <div className="flex flex-1 shrink-0 gap-10">
      {canSeePromotionsList && (
        <RouterTab to={promotionRouterTabUrl}>
          <FormattedMessage {...messages.promotions} />
        </RouterTab>
      )}
      {canSeeCooltraForEmployeesList && (
        <RouterTab to={cooltraForEmployeesRouterTabUrl}>
          <FormattedMessage {...messages.cooltraForEmployees} />
        </RouterTab>
      )}
      {canSeeStationBasedPromotionsList && (
        <RouterTab to={cooltraStationBasedRouterTabUrl}>
          <FormattedMessage {...messages.stationBased} />
        </RouterTab>
      )}
    </div>
  );
};
