import { useWorkShiftsQuery, WorkShiftListItem } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { Card, List } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { useEffect } from 'react';

import {
  ErrorPage,
  FilterFields,
  Four0Three,
  NoSearchResults,
  ResetFiltersButton,
} from '~/common';
import { yesterday } from '~/utils/date';
import { useNavigateWithQueryParams } from '~/hooks';

import { ShiftsListHeader } from './ShiftsListHeader/ShiftsListHeader';
import { ShiftCard, ShiftCardLoading } from './ShiftCard';
import {
  shiftsFiltersInitialValues,
  ShiftsFiltersFormValues,
} from './shifts-filters-form';
import { ShiftsTotal } from './ShiftsTotal/ShiftsTotal';
import * as ShiftsFilterFields from './ShiftsFilterFields';

export const ShiftsList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { values } = useFormContext<ShiftsFiltersFormValues>();

  const { data, isLoading, isError, error } = useWorkShiftsQuery(values);

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  const shiftsTotal = data?.workShifts.length || 0;

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      data-testid="SHIFTS_LIST"
      className="container min-w-3xl max-w-6xl py-12"
    >
      <Card className="p-8 mb-10" data-testid="SHIFTS_FILTERS">
        <div className="grid grid-cols-3 gap-y-6 gap-x-8 mb-12">
          <FilterFields.System />
          <ShiftsFilterFields.Operator />
          <FilterFields.Created />
        </div>
        <div className="flex justify-end">
          <ResetFiltersButton
            initialValues={{
              ...shiftsFiltersInitialValues,
              minDate: yesterday,
            }}
          />
        </div>
      </Card>
      <div className="flex items-center justify-between mb-12">
        <ShiftsTotal loading={isLoading} total={shiftsTotal} />
        <ShiftsFilterFields.Sort />
      </div>
      <List
        isLoading={isLoading}
        loadingRows={4}
        idProp="workShiftId"
        data={data?.workShifts || []}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<ShiftsListHeader />}
        renderRow={(shift: WorkShiftListItem) => <ShiftCard shift={shift} />}
        renderLoadingRow={<ShiftCardLoading />}
      />
    </div>
  );
};
