import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from '@cooltra/ui';
import {
  UserOffer,
  UserDiscount,
  UserPack,
  useUserOffersQuery,
  UserRentalPass,
} from '@cooltra/api';
import { useParams } from 'react-router-dom';
import { MdCheckCircleOutline } from 'react-icons/md';

import { RentalPass } from '../RentalPass/RentalPass';
import { Pack } from '../Pack/Pack';
import { Discount } from '../Discount/Discount';
import { OffersNavigator } from '../OffersNavigator';

import { ActiveOfferContractsLoading } from './ActiveOfferContractsLoading';
import messages from './messages';

export const ActiveOfferContracts = () => {
  const { id = '' } = useParams<'id'>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { data: offers, isLoading } = useUserOffersQuery(id);

  if (isLoading) {
    return <ActiveOfferContractsLoading />;
  }
  const getNavigatorOffers = (
    offer: UserOffer | undefined
  ): (UserPack | UserRentalPass | UserDiscount)[] => {
    if (offer === undefined) return [];
    if (!offer.activeRentalPass) return offer.redeemedDiscountPromotions;
    return [offer.activeRentalPass, ...offer.redeemedDiscountPromotions];
  };

  const getOffers = () => {
    if (
      !offers ||
      !Array.isArray(offers.redeemedDiscountPromotions) ||
      (offers.redeemedDiscountPromotions.length === 0 &&
        !offers.activeRentalPass)
    ) {
      return (
        <p className="w-full flex flex-col items-center justify-center text-center gap-2 text-xs text-neutral-300 py-10">
          <span className="text-2xl">
            <MdCheckCircleOutline />
          </span>
          <FormattedMessage {...messages.thereAreNoOffers} />
        </p>
      );
    }

    function isAPack(
      offer: UserDiscount | UserPack | UserRentalPass
    ): offer is UserPack {
      if ((offer as UserPack).purchasedOn) {
        return true;
      }

      return false;
    }

    function isADiscount(
      offer: UserPack | UserDiscount | UserRentalPass
    ): offer is UserDiscount {
      if ((offer as UserDiscount).discountPercentage) {
        return true;
      }

      return false;
    }

    return getNavigatorOffers(offers)
      .filter((_, index) => index === currentIndex)
      .map((offer) => {
        if (isAPack(offer)) {
          return (
            <Pack
              key={offer.title}
              title={offer.title}
              description={offer.description}
              purchasedOn={offer.purchasedOn}
              amount={offer.amount}
            />
          );
        }

        if (isADiscount(offer)) {
          return (
            <Discount
              key={offer.title}
              title={offer.title}
              description={offer.description}
              discountPercentage={offer.discountPercentage}
              state={offer.state}
              validFrom={offer.validFrom}
              validUntil={offer.validUntil}
              rentalsCap={offer.rentalsCap}
              rentalsUsed={offer.rentalsUsed}
              discountCap={offer.discountCap}
              discountUsed={offer.discountUsed}
              discountCapPerRental={offer.discountCapPerRental}
              targetVehicleModels={offer.targetVehicleModels}
            />
          );
        }

        return (
          <RentalPass
            key={offer.title}
            title={offer.title}
            validFrom={offer.validFrom}
            validUntil={offer.validUntil}
          />
        );
      });
  };

  return (
    <Card
      className="flex flex-col justify-between max-w-72 w-full p-6"
      data-testid="ACTIVE_OFFERS"
    >
      <span className="block text-neutral-400 text-xs mb-3">
        <FormattedMessage {...messages.offers} />
      </span>
      {getOffers()}
      <OffersNavigator
        currentIndex={currentIndex}
        offers={getNavigatorOffers(offers)}
        onChangeCurrentIndex={setCurrentIndex}
      />
    </Card>
  );
};
