import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Create promotion',
  },
  submitButton: {
    defaultMessage: 'Create promotion',
  },
  backToPromotions: {
    defaultMessage: 'Back to promotions',
  },
  youHaveSuccessfullyCreatedThePromotion: {
    defaultMessage: 'You have successfully created a new promotion!',
  },
  badFormattedFields: {
    defaultMessage:
      'This promotion cannot be created, please check that every field is correct',
  },
  codeDuplicated: {
    defaultMessage:
      'The code is duplicated and is already created in another active coupon promotion',
  },
});
