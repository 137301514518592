import { useTasksQuery, TaskListItem, useTasksTotal } from '@cooltra/api';
import { System, useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { InfiniteList, Card } from '@cooltra/ui';
import { useField, useFormContext } from '@cooltra/form';
import { useEffect } from 'react';

import {
  FilterFields,
  ErrorPage,
  Four0Three,
  NoSearchResults,
  TasksTotal,
  ResetFiltersButton,
  IssueFields,
} from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import * as TasksFilterFields from './TaskFilterFields';
import { TasksListHeader } from './TasksListHeader';
import { TaskCard, TaskCardLoading } from './TaskCard';
import {
  TasksFiltersFormValues,
  tasksFiltersInitialValues,
} from './tasks-filters-form';

export const TasksList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { hasPermission } = useAuthClaimsQuery();

  const { values } = useFormContext<TasksFiltersFormValues>();

  const queryParams = {
    ...values,
    operatorGroupId: values.operatorGroupId ? [values.operatorGroupId] : [],
  };
  const { data, isLoading, fetchNextPage, isFetchingNextPage, isError, error } =
    useTasksQuery(queryParams, {});

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  const tasks = data?.pages
    ? ([] as TaskListItem[]).concat.apply([], data.pages)
    : [];

  const tasksTotal = useTasksTotal(queryParams) || 0;
  const { value: systems } = useField<System[]>('system');

  if (!hasPermission('read:tasks')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className="container min-w-4xl max-w-6xl py-12">
      <Card className="p-8 mb-10">
        <div className="grid grid-cols-3 gap-y-6 gap-x-8 mb-10">
          <FilterFields.System />
          <FilterFields.Status />
          <TasksFilterFields.Tag />
          <IssueFields.Assignee />
          <IssueFields.Operator systems={systems} optional={false} />
        </div>
        <div className="flex justify-between items-center">
          <TasksFilterFields.Blocking />
          <ResetFiltersButton initialValues={tasksFiltersInitialValues} />
        </div>
      </Card>
      <div className="flex justify-between items-center mb-12">
        <TasksTotal loading={isLoading} total={tasksTotal} />
        <TasksFilterFields.Sort />
      </div>
      <InfiniteList
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasMore={tasks.length < tasksTotal}
        fetchMore={fetchNextPage}
        idProp="id"
        data={tasks}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<TasksListHeader />}
        renderLoadingRow={<TaskCardLoading />}
        renderRow={(task: TaskListItem) => (
          <TaskCard task={task} vehicle={task.vehicle} />
        )}
      />
    </div>
  );
};
