import { ReactNode, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getErrorStatus } from '@cooltra/axios';
import {
  Vehicle,
  useDisableAlarmMutation,
  useEnableAlarmMutation,
  useOpenSaddleMutation,
  useGpsRebootMutation,
  useIgnitionOnMutation,
  useIgnitionOffMutation,
} from '@cooltra/api';
import { Button, ButtonProps, Card, Divider } from '@cooltra/ui';
import { AxiosError } from 'axios';
import { useToggle } from '@cooltra/hooks';
import {
  MdOutlineMoreVert,
  MdBattery4Bar,
  MdOutlineSettingsBackupRestore,
  MdOutlineNotificationsActive,
  MdOutlineNotificationsOff,
} from 'react-icons/md';

import { useNotification, useOutsideElement } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import { errorMessages } from '../error-messages';

import messages from './messages';

export type NiuActionsProps = Pick<Vehicle, 'vehicleId'> &
  Pick<ButtonProps, 'emphasis'>;

export const NiuActionsDropdown = ({
  vehicleId,
  emphasis,
}: NiuActionsProps) => {
  const { formatMessage } = useIntl();
  const ref = useRef(null);
  const [isActionsOpen, { toggle, toggleOff }] = useToggle(false);
  const { activatePollingVehicle } = useVehiclePolling();
  const { addErrorNotification, addSuccessNotification } = useNotification();
  useOutsideElement(ref, toggleOff);

  const handleOnSuccess = (successMessage: string) => () => {
    addSuccessNotification(successMessage);
    activatePollingVehicle(vehicleId);
  };

  const handleOnError = (error: AxiosError) => {
    let errorNotification;

    switch (getErrorStatus(error)) {
      case 409:
        errorNotification = formatMessage(errorMessages.conflictInAction);
        break;
      case 422:
        errorNotification = formatMessage(
          errorMessages.notProperlyConfiguredVehicle
        );
        break;
      case 502:
        errorNotification = formatMessage(
          errorMessages.issueWithTelematicsProvider
        );
        break;
      case 504:
        errorNotification = formatMessage(errorMessages.vehicleIsOffline);
        break;
    }

    addErrorNotification(errorNotification);
  };

  const { isPending: isGpsRebootPending, mutate: mutateGpsReboot } =
    useGpsRebootMutation(vehicleId, {
      onError: handleOnError,
      onSuccess: handleOnSuccess(
        formatMessage(messages.successOnGpsRebootVehicle)
      ),
    });
  const { isPending: isOpenSaddlePending, mutate: mutateOpenSaddle } =
    useOpenSaddleMutation(vehicleId, {
      onError: handleOnError,
      onSuccess: handleOnSuccess(
        formatMessage(messages.successOnOpenSaddleVehicle)
      ),
    });
  const { isPending: isIgnitionOnPending, mutate: mutateIgnitionOn } =
    useIgnitionOnMutation(vehicleId, {
      onError: handleOnError,
      onSuccess: handleOnSuccess(
        formatMessage(messages.successOnIgnitionOnVehicle)
      ),
    });
  const { isPending: isIgnitionOffPending, mutate: mutateIgnitionOff } =
    useIgnitionOffMutation(vehicleId, {
      onError: handleOnError,
      onSuccess: handleOnSuccess(
        formatMessage(messages.successOffIgnitionOnVehicle)
      ),
    });
  const { isPending: isEnableAlarmPending, mutate: mutateEnableAlarm } =
    useEnableAlarmMutation(vehicleId, {
      onError: handleOnError,
      onSuccess: handleOnSuccess(
        formatMessage(messages.successOnEnableAlarmVehicle)
      ),
    });
  const { isPending: isDisableAlarmPending, mutate: mutateDisableAlarm } =
    useDisableAlarmMutation(vehicleId, {
      onError: handleOnError,
      onSuccess: handleOnSuccess(
        formatMessage(messages.successOnDisableAlarmVehicle)
      ),
    });
  const isPending =
    isGpsRebootPending ||
    isOpenSaddlePending ||
    isIgnitionOnPending ||
    isIgnitionOffPending ||
    isEnableAlarmPending ||
    isDisableAlarmPending;

  const getActionButton = (
    icon: ReactNode,
    text: string,
    onClick: () => void,
    loading?: boolean
  ) => {
    return (
      <Button
        size="sm"
        emphasis="low"
        className="w-full border-0 rounded-none py-5 text-neutral-1000"
        onClick={onClick}
        loading={loading}
      >
        <div className="flex gap-4 items-center">
          {icon}
          <span className="w-32 text-left font-normal text-sm">{text}</span>
        </div>
      </Button>
    );
  };

  return (
    <div ref={ref} className="relative">
      <Button
        size="sm"
        emphasis={emphasis}
        loading={isPending}
        onClick={toggle}
        trailingIcon={<MdOutlineMoreVert />}
      >
        <span className="text-sm">
          <FormattedMessage {...messages.moreActions} />
        </span>
      </Button>
      {isActionsOpen && (
        <Card className="w-48 absolute top-9 right-0 z-30">
          {getActionButton(
            <MdOutlineSettingsBackupRestore className="w-5 h-5" />,
            formatMessage(messages.resetGPS),
            mutateGpsReboot,
            isGpsRebootPending
          )}
          <Divider />
          {getActionButton(
            <MdBattery4Bar className="w-5 h-5" />,
            formatMessage(messages.openSaddle),
            mutateOpenSaddle,
            isOpenSaddlePending
          )}
          <Divider />
          {getActionButton(
            <MdOutlineSettingsBackupRestore className="w-5 h-5" />,
            formatMessage(messages.ignitionOn),
            mutateIgnitionOn,
            isIgnitionOnPending
          )}
          <Divider />
          {getActionButton(
            <MdOutlineSettingsBackupRestore className="w-5 h-5" />,
            formatMessage(messages.ignitionOff),
            mutateIgnitionOff,
            isIgnitionOffPending
          )}
          <Divider />
          {getActionButton(
            <MdOutlineNotificationsActive className="w-5 h-5" />,
            formatMessage(messages.enableAlarm),
            mutateEnableAlarm,
            isEnableAlarmPending
          )}
          <Divider />
          {getActionButton(
            <MdOutlineNotificationsOff className="w-5 h-5" />,
            formatMessage(messages.disableAlarm),
            mutateDisableAlarm,
            isDisableAlarmPending
          )}
        </Card>
      )}
    </div>
  );
};
