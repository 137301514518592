export type VehicleBatteryStatus =
  | 'NONE'
  | 'DISCHARGED'
  | 'LOW'
  | 'MEDIUM'
  | 'NEUTRAL'
  | 'HIGH';

export const getBatteryStatus = (
  batteryLevel: number | undefined | null
): VehicleBatteryStatus => {
  if (batteryLevel === undefined || batteryLevel === null) {
    return 'NONE';
  }

  if (batteryLevel == 0) {
    return 'DISCHARGED';
  }

  if (batteryLevel < 15) {
    return 'LOW';
  }

  if (batteryLevel >= 15 && batteryLevel < 30) {
    return 'MEDIUM';
  }

  if (batteryLevel >= 30 && batteryLevel < 45) {
    return 'NEUTRAL';
  }

  return 'HIGH';
};
