import { VehiclesSort } from '@cooltra/api';
import { useIntl } from 'react-intl';

import { SortField } from '../../../SortField/SortField';

import messages from './messages';

type SortOption = { label: any; value: VehiclesSort };

const options: SortOption[] = [
  {
    label: messages.externalIdAscending,
    value: 'externalId.asc',
  },
  {
    label: messages.externalIdDescending,
    value: 'externalId.desc',
  },
  {
    label: messages.inLocationFromAscending,
    value: 'inLocationFrom.asc',
  },
  {
    label: messages.inLocationFromDescending,
    value: 'inLocationFrom.desc',
  },
  {
    label: messages.remainingMileageToService,
    value: 'services.remainingMileageToService.asc',
  },
  {
    label: messages.remainingDaysToTechnicalInspection,
    value: 'technicalInspections.remainingDaysToTechnicalInspection.asc',
  },
  {
    label: messages.idleFromAsc,
    value: 'idleFrom.asc',
  },
  {
    label: messages.idleFromDesc,
    value: 'idleFrom.desc',
  },
];

export const Sort = () => {
  const { formatMessage } = useIntl();

  const translatedOptions = options.map(({ label, value }) => ({
    label: formatMessage(label),
    value,
  }));

  return <SortField options={translatedOptions} />;
};
