import { FormattedMessage, useIntl } from 'react-intl';
import { Vehicle, useSyncVehicleMutation } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { Button, ButtonProps } from '@cooltra/ui';
import { AxiosError } from 'axios';

import { useNotification } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import { errorMessages } from '../error-messages';

import messages from './messages';

export type SyncProps = Pick<Vehicle, 'vehicleId'> &
  Pick<ButtonProps, 'emphasis'>;

export const Sync = ({ vehicleId, emphasis }: SyncProps) => {
  const { formatMessage } = useIntl();
  const { activatePollingVehicle } = useVehiclePolling();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleOnSuccess = () => {
    addSuccessNotification(formatMessage(messages.syncSuccess));
    activatePollingVehicle(vehicleId);
  };

  const handleOnError = (error: AxiosError) => {
    let errorNotification;

    switch (getErrorStatus(error)) {
      case 409:
        errorNotification = formatMessage(errorMessages.conflictInAction);
        break;
      case 422:
        errorNotification = formatMessage(
          errorMessages.notProperlyConfiguredVehicle
        );
        break;
      case 502:
        errorNotification = formatMessage(
          errorMessages.issueWithTelematicsProvider
        );
        break;
      case 504:
        errorNotification = formatMessage(errorMessages.vehicleIsOffline);
        break;
    }

    addErrorNotification(errorNotification);
  };

  const { isPending, mutate } = useSyncVehicleMutation(vehicleId, {
    onError: handleOnError,
    onSuccess: handleOnSuccess,
  });

  return (
    <Button
      size="sm"
      emphasis={emphasis}
      loading={isPending}
      onClick={() => mutate()}
    >
      <FormattedMessage {...messages.sync} />
    </Button>
  );
};
