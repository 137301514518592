import { useInvoiceQuery, useUserPaymentMethodQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { ScrollToTop, SpinnerScreen } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  DividedCard,
  ErrorPage,
  Four0Three,
  InvoiceNewChargeAttempt,
  InvoiceState,
} from '~/common';
import { isCreditNote } from '~/utils/invoice';
import { AddInvoiceCustomerRetries } from '~/components/Invoice/AddInvoiceCustomerRetries/AddInvoiceCustomerRetries';

import { ChargeAttempts } from '../ChargeAttempts/ChargeAttempts/ChargeAttempts';
import { InvoiceBackLink } from '../InvoiceBackLink/InvoiceBackLink';
import { InvoiceItems } from '../InvoiceItems/InvoiceItems';
import { InvoiceSummary } from '../InvoiceSummary/InvoiceSummary';
import { MarkInvoiceAsPaid } from '../MarkInvoiceAsPaid';

import messages from './messages';

export const Invoice = () => {
  const { invoiceId = '' } = useParams();

  const {
    data: invoice,
    isError: isInvoiceError,
    error: invoiceError,
    refetch: refetchInvoice,
  } = useInvoiceQuery(invoiceId);
  const userId = invoice?.userId ?? '';
  const { data: paymentMethod } = useUserPaymentMethodQuery(userId, {
    enabled: !!userId,
  });

  if (isInvoiceError && getErrorStatus(invoiceError) === 403) {
    return <Four0Three />;
  }

  if (isInvoiceError) {
    return <ErrorPage />;
  }

  if (!invoice) {
    return <SpinnerScreen />;
  }

  return (
    <div className="pt-12 pb-6">
      <ScrollToTop />
      <DividedCard
        className="min-w-5xl max-w-6xl"
        renderHeader={
          <div data-testid="INVOICE_HEADER">
            <div className="mb-4">
              <InvoiceBackLink
                userId={invoice.userId}
                provider={invoice.userProvider}
              />
            </div>
            <div className="flex items-center">
              <div className="flex flex-1 gap-6 items-center">
                <h2 className="text-3xl">
                  {isCreditNote(invoice.type) ? (
                    <FormattedMessage
                      {...messages.titleForCreditsNote}
                      values={{ invoiceNumber: invoice.invoiceNumber }}
                    />
                  ) : (
                    <FormattedMessage
                      {...messages.title}
                      values={{ invoiceNumber: invoice.invoiceNumber }}
                    />
                  )}
                </h2>
                <InvoiceState state={invoice.state} />
              </div>
              <div className="flex gap-3">
                <MarkInvoiceAsPaid
                  invoiceId={invoiceId}
                  state={invoice.state}
                  type={invoice.type}
                />
                {paymentMethod != null && paymentMethod.type === 'card' && (
                  <InvoiceNewChargeAttempt
                    invoiceId={invoiceId}
                    invoice={invoice}
                    refetchInvoice={refetchInvoice}
                  />
                )}
                <AddInvoiceCustomerRetries
                  invoiceId={invoiceId}
                  isPaid={invoice.state === 'PAID'}
                />
              </div>
            </div>
          </div>
        }
        renderLeft={<InvoiceSummary invoice={invoice} invoiceId={invoiceId} />}
        renderRight={
          <div className="flex flex-col gap-10">
            <ChargeAttempts chargeAttempts={invoice.chargeAttempts || []} />
            <InvoiceItems
              items={invoice.items}
              isACreditNote={isCreditNote(invoice.type)}
            />
          </div>
        }
      />
    </div>
  );
};
