const getBatteryColor = (batteryLevel: number): string => {
  if (batteryLevel == 0) {
    return 'fill-neutral-0';
  }

  if (batteryLevel < 15) {
    return 'fill-danger-400';
  }

  if (batteryLevel >= 15 && batteryLevel < 30) {
    return 'fill-warning-400';
  }

  if (batteryLevel >= 30 && batteryLevel < 45) {
    return 'fill-neutral-alternative-700';
  }

  return 'fill-success-400';
};

const NoBattery = () => (
  <g>
    <path
      className="fill-neutral-50"
      fillRule="evenodd"
      d="M56.25 90H47a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h33.25l-24-24Zm28.993 24H91a2 2 0 0 0 2-2v-4h5a1 1 0 0 0 1-1V97a1 1 0 0 0-1-1h-5v-4a2 2 0 0 0-2-2H61.243l24 24Z"
      clipRule="evenodd"
      opacity=".6"
    />
  </g>
);

export type BatteryProps = {
  batteryCharge: number | null | undefined;
};

export const Battery = ({ batteryCharge }: BatteryProps) => {
  if (batteryCharge === undefined || batteryCharge === null) {
    return <NoBattery />;
  }

  const color = getBatteryColor(batteryCharge);

  const maxWidth = 42;
  const width = (batteryCharge / 100) * maxWidth;

  return (
    <g>
      <rect
        width="48"
        height="24"
        x="45"
        y="90"
        className="fill-neutral-50"
        rx="2"
      ></rect>
      <rect
        width="15"
        height="12"
        x="84"
        y="96"
        className="fill-neutral-50"
        rx="1"
      ></rect>
      <rect
        width={width}
        height="18"
        x="48"
        y="93"
        className={color}
        rx="2"
      ></rect>
    </g>
  );
};
