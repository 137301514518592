import { Rental, useRentalTimelineQuery } from '@cooltra/api';
import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import {
  reduceRentalTimeline,
  RentalTimelineItemReduced,
} from '~/libs/reduce-rental-timeline/reduce-rental-timeline';

import {
  ReservationItem,
  EventItem,
  CheckoutFailureItem,
  VehicleStatusItem,
  CheckoutDismissibleErrorsItem,
} from './RentalTimelineItems';
import { TimelineLoading } from './TimelineLoading';
import messages from './messages';
import { VehicleTelematicStatus } from './RentalTimelineItems/VehicleTelematicStatus/VehicleTelematicStatus';

export type TimelineProps = Pick<Rental, 'rentalId' | 'reservation'> & {
  isRentalActive: boolean;
  isABike: boolean;
};

export const Timeline = ({
  rentalId,
  reservation,
  isRentalActive,
  isABike,
}: TimelineProps) => {
  const {
    data: timeline,
    isLoading,
    isError,
  } = useRentalTimelineQuery(rentalId);

  if (isError) {
    return (
      <Card className="w-full py-10 pl-16 pr-8" data-testid="RENTAL_TIMELINE">
        <p className="text-neutral-400 text-center text-sm">
          <FormattedMessage {...messages.timelineError} />
        </p>
      </Card>
    );
  }

  if (isLoading || !timeline) {
    return <TimelineLoading />;
  }

  const reducedTimeline = reduceRentalTimeline(timeline);
  const eventsTimeline = reducedTimeline.filter((timelineItem) =>
    [
      'RentalStarted',
      'RentalPaused',
      'RentalResumed',
      'RentalCompleted',
      'RentalClosed',
    ].includes(timelineItem.event)
  );
  const rentalStarted = eventsTimeline.find(
    (event) => event.event === 'RentalStarted'
  );
  const eventsTimelineIterator = eventsTimeline[Symbol.iterator]();
  const checkInEvent = eventsTimelineIterator.next().value;

  return (
    <Card className="pt-8 pr-6 pl-4" data-testid="RENTAL_TIMELINE">
      <div className="relative">
        <div className="h-full w-px absolute left-14 bg-neutral-200 ml-2" />
        <ReservationItem
          reservation={reservation}
          checkInEvent={checkInEvent}
        />
        {reducedTimeline.map(
          (
            {
              event,
              occurredOn,
              status,
              error,
              attemptsTimes,
              endAttemptsOn,
              dismissibleErrors,
              closedBy,
            }: RentalTimelineItemReduced,
            index: number
          ) => {
            const isLast = reducedTimeline?.length - 1 === index;

            if (status) {
              return (
                <VehicleStatusItem
                  key={occurredOn}
                  status={status}
                  occurredOn={occurredOn}
                  isABike={isABike}
                  isLast={isLast}
                  rentalActiveOccurredOn={rentalStarted?.occurredOn}
                />
              );
            }

            if (error) {
              return (
                !!rentalStarted && (
                  <CheckoutFailureItem
                    isLast={isLast}
                    key={occurredOn}
                    error={error}
                    occurredOn={occurredOn}
                    endAttemptsOn={endAttemptsOn}
                    attemptsTimes={attemptsTimes}
                    isRentalActive={isRentalActive}
                    rentalActiveOccurredOn={rentalStarted.occurredOn}
                  />
                )
              );
            }

            if (dismissibleErrors) {
              return (
                <CheckoutDismissibleErrorsItem
                  isLast={isLast}
                  key={occurredOn}
                  dismissibleErrors={dismissibleErrors}
                  occurredOn={occurredOn}
                />
              );
            }

            if (event === 'VehicleWentOffline') {
              return (
                <VehicleTelematicStatus
                  occurredOn={occurredOn}
                  key={occurredOn}
                  variant={'offline'}
                />
              );
            }

            if (event === 'VehicleWentOnline') {
              return (
                <VehicleTelematicStatus
                  occurredOn={occurredOn}
                  key={occurredOn}
                  variant={'online'}
                />
              );
            }

            return (
              <EventItem
                key={occurredOn}
                event={event}
                occurredOn={occurredOn}
                nextEventOccurredOn={
                  eventsTimelineIterator.next().value?.occurredOn
                }
                isLast={isLast}
                closedBy={closedBy}
              />
            );
          }
        )}
      </div>
    </Card>
  );
};
