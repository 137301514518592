import {
  ProblemReportListItem,
  useProblemReportsQuery,
  useProblemReportsTotal,
} from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { Card, InfiniteList } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { useEffect } from 'react';

import {
  ErrorPage,
  FilterFields,
  Four0Three,
  NoSearchResults,
  ProblemReportsTotal,
  ResetFiltersButton,
} from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import * as ProblemReportsFilterFields from './ProblemReportsFilterFields';
import { ProblemReportsListHeader } from './ProblemReportsListHeader';
import {
  ProblemReportCard,
  ProblemReportCardLoading,
} from './ProblemReportCard';
import {
  ProblemReportsFiltersFormValues,
  initialProblemReportsFiltersFormValues,
} from './problem-reports-filters-form';

export const ProblemReportsList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { hasPermission } = useAuthClaimsQuery();

  const { values } = useFormContext<ProblemReportsFiltersFormValues>();

  const { data, isLoading, fetchNextPage, isFetchingNextPage, isError, error } =
    useProblemReportsQuery(values);

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  const problemReports = data?.pages
    ? ([] as ProblemReportListItem[]).concat.apply([], data.pages)
    : [];

  const problemReportsTotal = useProblemReportsTotal(values) || 0;

  if (!hasPermission('read:problem_reports')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className="container min-w-4xl max-w-6xl py-12">
      <Card className="p-8 mb-10">
        <div className="grid grid-cols-3 gap-y-6 gap-x-8 mb-12">
          <FilterFields.System />
          <FilterFields.Status />
          <FilterFields.Created />
        </div>
        <div className="flex justify-end">
          <ResetFiltersButton
            initialValues={initialProblemReportsFiltersFormValues}
          />
        </div>
      </Card>
      <div className="flex justify-between items-center mb-12">
        <ProblemReportsTotal loading={isLoading} total={problemReportsTotal} />
        <ProblemReportsFilterFields.Sort />
      </div>
      <InfiniteList
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasMore={problemReports.length < problemReportsTotal}
        fetchMore={fetchNextPage}
        idProp="problemReportId"
        data={problemReports}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<ProblemReportsListHeader />}
        renderLoadingRow={<ProblemReportCardLoading />}
        renderRow={(problemReport: ProblemReportListItem) => (
          <ProblemReportCard problemReport={problemReport} />
        )}
      />
    </div>
  );
};
