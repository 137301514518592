import { FormattedMessage, useIntl } from 'react-intl';
import {
  usePromotionWebOfferMutation,
  usePromotionCouponMutation,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormButton, FormHelpers } from '@cooltra/form';
import { useNavigate } from 'react-router-dom';

import {
  Four0Three,
  RouterBackLink,
  StationBasedFormValues,
  stationBasedInitialFormValues,
  StationBasedPromotionForm,
} from '~/common';
import { useNotification } from '~/hooks';

import { StationBasedFormFields } from '../StationBasedFormFields/StationBasedFormFields';

import {
  getCreatePromotionCouponPayload,
  getCreatePromotionWebOfferPayload,
} from './get-create-station-base-promotion-payload';
import messages from './messages';

export const CreateStationBasedPromotion = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const { mutateAsync: webOfferMutateAsync, isPending: webOfferIsPending } =
    usePromotionWebOfferMutation();
  const { mutateAsync: couponMutateAsync, isPending: couponIsPending } =
    usePromotionCouponMutation();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  if (!hasPermission('write:station_based_offers')) {
    return <Four0Three />;
  }

  const onSubmitStationBasedPromotionWebOfferForm = (
    values: StationBasedFormValues,
    { resetForm }: FormHelpers<StationBasedFormValues>
  ) => {
    const payload = getCreatePromotionWebOfferPayload(values);

    return webOfferMutateAsync(payload)
      .then(({ promotionId }) => {
        resetForm(stationBasedInitialFormValues);
        addSuccessNotification(
          formatMessage(messages.youHaveSuccessfullyCreatedThePromotion)
        );
        navigate(`/offers/station-based/${promotionId}/edit`);
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 400:
            addErrorNotification(formatMessage(messages.badFormattedFields));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  const onSubmitStationBasedPromotionCouponForm = (
    values: StationBasedFormValues,
    { resetForm }: FormHelpers<StationBasedFormValues>
  ) => {
    const payload = getCreatePromotionCouponPayload(values);

    return couponMutateAsync(payload)
      .then(({ promotionId }) => {
        resetForm(stationBasedInitialFormValues);
        addSuccessNotification(
          formatMessage(messages.youHaveSuccessfullyCreatedThePromotion)
        );
        navigate(`/offers/station-based/${promotionId}/edit`);
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 400:
            addErrorNotification(formatMessage(messages.badFormattedFields));
            break;
          case 422:
            addErrorNotification(formatMessage(messages.codeDuplicated));
            break;
          default:
            addErrorNotification();
            break;
        }
      });
  };

  const onSubmitStationBasedPromotionForm = (
    values: StationBasedFormValues,
    formHelpers: FormHelpers<StationBasedFormValues>
  ) => {
    if (values.promotionTarget === 'WEB_OFFER') {
      return onSubmitStationBasedPromotionWebOfferForm(values, formHelpers);
    }

    if (values.promotionTarget === 'COUPON') {
      return onSubmitStationBasedPromotionCouponForm(values, formHelpers);
    }

    return Promise.reject();
  };

  return (
    <div
      className="container min-w-screen-sm max-w-3xl py-12"
      data-testid="CREATE_STATION_BASED_PROMOTION"
    >
      <div className="mb-9">
        <RouterBackLink to="/offers/station-based">
          <FormattedMessage {...messages.backToPromotions} />
        </RouterBackLink>
        <h1 className="text-3xl">
          <FormattedMessage {...messages.title} />
        </h1>
      </div>
      <StationBasedPromotionForm
        initialValues={stationBasedInitialFormValues}
        onSubmit={onSubmitStationBasedPromotionForm}
      >
        <StationBasedFormFields />
        <div className="flex justify-end">
          <FormButton loading={webOfferIsPending || couponIsPending}>
            <FormattedMessage {...messages.submitButton} />
          </FormButton>
        </div>
      </StationBasedPromotionForm>
    </div>
  );
};
