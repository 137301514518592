import { ProgressBar } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';

export type BatteryStateProps = {
  value: number;
  className?: string;
};

export const BatteryState = ({ value, className }: BatteryStateProps) => (
  <div className={classNames('flex w-20 items-center gap-1', className)}>
    <p className="text-right text-xs font-semibold text-neutral-800">
      {value}%
    </p>
    <div className="h-3 w-full">
      <ProgressBar value={value} />
    </div>
  </div>
);
