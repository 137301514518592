import { OperationalState } from '@cooltra/api';
import { isBike } from '@cooltra/vehicle-utils';

import { ExtendedVehicleListItem } from '~/libs/vehicles-filters';

import { VehicleFeature } from '../../VehiclesMap/types';

import { VehicleFeatureImageProps, VehicleFeatureBuilderProps } from './types';

export class VehicleFeatureBuilder implements VehicleFeatureBuilderProps {
  readonly vehicleId: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly battery: number | undefined | null;
  readonly operationalState: OperationalState;
  readonly numberOfOpenTasks: number;
  readonly isABike: boolean;
  readonly isSelected: boolean;
  readonly isMatchWithFilters: boolean;
  readonly isRented: boolean;
  readonly isReserved: boolean;
  readonly bonusEnabled: boolean;

  constructor(
    vehicleId: string,
    latitude: number,
    longitude: number,
    battery: number | undefined | null,
    operationalState: OperationalState,
    numberOfOpenTasks: number,
    isABike: boolean,
    isSelected: boolean,
    isMatchWithFilters: boolean,
    isRented: boolean,
    isReserved: boolean,
    bonusEnabled: boolean
  ) {
    this.vehicleId = vehicleId;
    this.latitude = latitude;
    this.longitude = longitude;
    this.battery = battery;
    this.operationalState = operationalState;
    this.numberOfOpenTasks = numberOfOpenTasks;
    this.isABike = isABike;
    this.isSelected = isSelected;
    this.isMatchWithFilters = isMatchWithFilters;
    this.isRented = isRented;
    this.isReserved = isReserved;
    this.bonusEnabled = bonusEnabled;
  }

  buildFeature(vehicleFeatureImage: VehicleFeatureImageProps): VehicleFeature {
    return {
      type: 'Feature',
      properties: {
        vehicleId: this.vehicleId,
        vehicleImageName: vehicleFeatureImage.getImageName(this),
        vehicleOperationalState: this.operationalState,
        numberOfOpenTasks: this.numberOfOpenTasks,
        battery: this.battery,
        isSelected: this.isSelected,
        isMatchWithFilters: this.isMatchWithFilters,
        isRented: this.isRented,
        isReserved: this.isReserved,
        bonusEnabled: this.bonusEnabled,
      },
      geometry: {
        type: 'Point',
        coordinates: [this.longitude, this.latitude, 0.0],
      },
    };
  }
}

export const getFeaturesFromVehiclesList = (
  vehicles: ExtendedVehicleListItem[],
  vehicleFeatureImage: VehicleFeatureImageProps,
  selectedVehicleId: string | undefined
): VehicleFeature[] => {
  const vehicleFeatures: VehicleFeature[] = [];

  vehicles.forEach((vehicle) => {
    const { geoLocation, batteryCharge } = vehicle.status;

    if (
      geoLocation &&
      typeof geoLocation.latitude === 'number' &&
      typeof geoLocation.longitude === 'number'
    ) {
      const vehicleFeatureBuilder = new VehicleFeatureBuilder(
        vehicle.vehicleId,
        geoLocation.latitude,
        geoLocation.longitude,
        batteryCharge?.value,
        vehicle.operationalState,
        vehicle.openTasks.length,
        isBike(vehicle),
        vehicle.vehicleId === selectedVehicleId,
        vehicle.isMatch,
        vehicle.operationalConditions.includes('RENTED'),
        vehicle.operationalConditions.includes('RESERVED'),
        vehicle.bonusEnabled
      );

      vehicleFeatures.push(
        vehicleFeatureBuilder.buildFeature(vehicleFeatureImage)
      );
    }
  });

  return vehicleFeatures;
};
