import { RentalState } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { isBike } from '@cooltra/vehicle-utils';

import { ActionButtons } from '~/common';
import { useVehicleQuery } from '~/hooks';

export type TelematicsInformationProps = {
  vehicleId: string;
  rentalState: RentalState;
};

export const Telematics = ({
  vehicleId,
  rentalState,
}: TelematicsInformationProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { data: vehicle } = useVehicleQuery(vehicleId);

  const userCanSeeMotorcycleTelematicsActionsButtons =
    hasPermission('action:vehicles') &&
    vehicle &&
    !isBike(vehicle) &&
    rentalState === 'ACTIVE';

  const userCanSeeBikeTelematicsActionsButtons =
    hasPermission('action:vehicles') &&
    vehicle &&
    isBike(vehicle) &&
    rentalState === 'ACTIVE';

  const isNiu = vehicle?.telematics === 'NIU';

  return (
    <div className="flex gap-2" data-testid="RENTAL_TELEMATICS_ACTIONS">
      {userCanSeeMotorcycleTelematicsActionsButtons && (
        <>
          <ActionButtons.Lock
            vehicleId={vehicleId}
            emphasis="high"
            showIcon={false}
          />
          <ActionButtons.Unlock
            vehicleId={vehicleId}
            emphasis="high"
            showIcon={false}
          />
          <ActionButtons.Honk
            vehicleId={vehicleId}
            emphasis="high"
            showIcon={false}
          />
          <ActionButtons.Sync vehicleId={vehicleId} emphasis="high" />
          <ActionButtons.UnlockTopCase
            vehicleId={vehicleId}
            emphasis="high"
            showIcon={false}
          />
          {isNiu && (
            <ActionButtons.NiuActionsDropdown
              vehicleId={vehicleId}
              emphasis="high"
            />
          )}
        </>
      )}
      {userCanSeeBikeTelematicsActionsButtons && (
        <>
          <ActionButtons.Lock
            vehicleId={vehicleId}
            emphasis="high"
            showIcon={false}
          />
          <ActionButtons.Unlock
            vehicleId={vehicleId}
            emphasis="high"
            showIcon={false}
          />
          <ActionButtons.Sync vehicleId={vehicleId} emphasis="high" />
          <ActionButtons.PowerOff
            vehicleId={vehicleId}
            emphasis="high"
            showIcon={false}
          />
        </>
      )}
    </div>
  );
};
