import { RouterButton } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { usePromotionsQuery } from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { Card, List } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { useEffect } from 'react';

import {
  ErrorPage,
  Four0Three,
  NoSearchResults,
  ResetFiltersButton,
  getStationBasedStateType,
} from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import { OffersNavigationTabs } from '../../../OffersNavigationTabs/OffersNavigationTabs';
import {
  StationBasedPromotionCard,
  StationBasedPromotionCardLoading,
} from '../Card';
import { StationBasedPromotionsListHeader } from '../ListHeader/StationBasedPromotionsListHeader';
import * as PromotionsFilterFields from '../FilterFields';

import messages from './messages';
import {
  PromotionsFiltersFormValues,
  initialPromotionsFiltersFormValues,
} from './promotions-filters-form';

export const StationBasedList = () => {
  const { hasPermission } = useAuthClaimsQuery();
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { values } = useFormContext<PromotionsFiltersFormValues>();

  const canCreatePromotion = hasPermission('write:station_based_offers');

  const {
    data: stationBasedPromotions,
    isLoading,
    isError,
    error,
  } = usePromotionsQuery(values);

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  if (!hasPermission('read:station_based_offers')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const promotions = values.state
    ? stationBasedPromotions?.promotions.filter(
        (promotion) =>
          getStationBasedStateType({
            validFrom: promotion.validFrom,
            validUntil: promotion.validUntil,
          }) === values.state
      ) || []
    : stationBasedPromotions?.promotions || [];

  return (
    <div className="container min-w-5xl max-w-6xl py-12">
      <div className="flex items-center justify-between mb-6">
        <OffersNavigationTabs />
        {canCreatePromotion && (
          <RouterButton emphasis="high" to="/offers/station-based/new">
            <FormattedMessage {...messages.createStationBasedPromotion} />
          </RouterButton>
        )}
      </div>
      <Card className="p-8 mb-10">
        <div className="grid grid-cols-3 gap-y-6 gap-x-8 mb-12">
          <PromotionsFilterFields.Keyword />
          <PromotionsFilterFields.State />
          <PromotionsFilterFields.Type />
        </div>
        <div className="flex justify-end items-end">
          <ResetFiltersButton
            initialValues={initialPromotionsFiltersFormValues}
          />
        </div>
      </Card>
      <div className="flex justify-between items-center mb-12"></div>
      <List
        isLoading={isLoading}
        loadingRows={4}
        idProp="promotionId"
        data={promotions}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<StationBasedPromotionsListHeader />}
        renderLoadingRow={<StationBasedPromotionCardLoading />}
        renderRow={(stationBasedPromotion) => (
          <StationBasedPromotionCard promotion={stationBasedPromotion} />
        )}
      />
    </div>
  );
};
