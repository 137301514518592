import { Incident, useIncidentsQuery, useIncidentsTotal } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { Card, InfiniteList } from '@cooltra/ui';
import { useFormContext } from '@cooltra/form';
import { useEffect } from 'react';

import {
  ErrorPage,
  FilterFields,
  Four0Three,
  IncidentsTotal,
  NoSearchResults,
  ResetFiltersButton,
  IncidentsListHeader,
  IncidentCard,
  IncidentCardLoading,
} from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import * as IncidentFilterFields from './IncidentFilterFields';
import {
  IncidentsFiltersFormValues,
  incidentsFiltersInitialValues,
} from './incidents-filters-form';

export const IncidentsList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { hasPermission } = useAuthClaimsQuery();

  const { values } = useFormContext<IncidentsFiltersFormValues>();

  const { data, isLoading, fetchNextPage, isFetchingNextPage, isError, error } =
    useIncidentsQuery(values);

  useEffect(() => {
    navigateWithQueryParams(values);
  }, [navigateWithQueryParams, values]);

  const incidents = data?.pages
    ? ([] as Incident[]).concat.apply([], data.pages)
    : [];

  const incidentsTotal = useIncidentsTotal(values) || 0;

  if (!hasPermission('read:incidents')) {
    return <Four0Three />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      className="container min-w-4xl max-w-6xl py-12"
      data-testid="INCIDENT_LIST"
    >
      <Card className="p-8 mb-10">
        <div className="grid grid-cols-3 gap-y-6 gap-x-8 mb-12">
          <FilterFields.System />
          <IncidentFilterFields.Assignee />
          <FilterFields.Status />
        </div>
        <div className="flex justify-end items-end">
          <ResetFiltersButton initialValues={incidentsFiltersInitialValues} />
        </div>
      </Card>
      <div className="flex justify-between items-center mb-12">
        <IncidentsTotal loading={isLoading} total={incidentsTotal} />
        <IncidentFilterFields.Sort />
      </div>
      <InfiniteList
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        fetchMore={fetchNextPage}
        hasMore={incidents.length < incidentsTotal}
        idProp="incidentId"
        data={incidents}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<IncidentsListHeader />}
        renderLoadingRow={<IncidentCardLoading />}
        renderRow={(incident: Incident) => <IncidentCard incident={incident} />}
      />
    </div>
  );
};
