import { FormattedMessage, useIntl } from 'react-intl';
import { Vehicle, useUnlockTopCaseMutation } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { Button, ButtonProps } from '@cooltra/ui';
import { AxiosError } from 'axios';
import { MdOutlineSportsMotorsports } from 'react-icons/md';

import { useNotification } from '~/hooks';
import { useVehiclePolling } from '~/libs/polling-data';

import { errorMessages } from '../error-messages';

import messages from './messages';

export type UnlockTypeCase = Pick<Vehicle, 'vehicleId'> &
  Pick<ButtonProps, 'emphasis'> & { showIcon: boolean };

export const UnlockTopCase = ({
  vehicleId,
  emphasis,
  showIcon,
}: UnlockTypeCase) => {
  const { formatMessage } = useIntl();
  const { activatePollingVehicle } = useVehiclePolling();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleOnSuccess = () => {
    addSuccessNotification(
      formatMessage(messages.successOnUnlockTopCaseVehicle)
    );
    activatePollingVehicle(vehicleId);
  };

  const handleOnError = (error: AxiosError) => {
    let errorNotification;

    switch (getErrorStatus(error)) {
      case 409:
        errorNotification = formatMessage(errorMessages.conflictInAction);
        break;
      case 422:
        errorNotification = formatMessage(
          errorMessages.notProperlyConfiguredVehicle
        );
        break;
      case 502:
        errorNotification = formatMessage(
          errorMessages.issueWithTelematicsProvider
        );
        break;
      case 504:
        errorNotification = formatMessage(errorMessages.vehicleIsOffline);
        break;
    }

    addErrorNotification(errorNotification);
  };

  const { isPending, mutate } = useUnlockTopCaseMutation(vehicleId, {
    onError: handleOnError,
    onSuccess: handleOnSuccess,
  });

  return (
    <Button
      size={showIcon ? 'lg' : 'sm'}
      emphasis={emphasis}
      loading={isPending}
      onClick={() => mutate()}
      className={showIcon ? 'w-full p-4' : ''}
    >
      <div className="flex flex-col items-center justify-center">
        {showIcon && (
          <MdOutlineSportsMotorsports className="w-5 h-5 mb-1 mt-1" />
        )}
        <span className="text-sm">
          <FormattedMessage {...messages.unlockTopCase} />
        </span>
      </div>
    </Button>
  );
};
