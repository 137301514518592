import { Model, Telematics, VehicleListItem } from '@cooltra/api';
import { Icon, ProgressBar } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdCheck } from 'react-icons/md';

import { ModelImage, ModelName, TelematicsName, VehicleType } from '~/common';

import messages from './messages';

export type VehicleTooltipProps = {
  model: Model;
  telematics: Telematics;
  cityHallPublished: boolean;
} & Pick<VehicleListItem, 'status' | 'identificationNumber'>;

export const VehicleTooltip = ({
  cityHallPublished,
  model,
  telematics,
  status,
  identificationNumber,
}: VehicleTooltipProps) => (
  <div className="w-64">
    {cityHallPublished && (
      <div className="bg-neutral-50 px-4 py-1.5 rounded-t-lg">
        <span className="text-xs text-neutral-400 inline-flex items-center">
          <Icon className="mr-1">
            <MdCheck />
          </Icon>
          <FormattedMessage {...messages.sharedWithCityHall} />
        </span>
      </div>
    )}
    <div className="p-4">
      <div className="flex items-center gap-3">
        <ModelImage
          model={model}
          identificationNumber={identificationNumber}
          className="h-12"
        />
        <div>
          <span className="text-xs text-neutral-500 block mb-1">
            <VehicleType model={model} />
          </span>
          <span className="text-sm font-semibold text-neutral-900">
            <ModelName model={model} /> (
            <TelematicsName telematics={telematics} />)
          </span>
        </div>
      </div>
      {status && (
        <div className="mt-4 flex items-center gap-5">
          {status.batteryCharge && (
            <div>
              <span className="text-xs text-neutral-500 block mb-1">
                <FormattedMessage {...messages.battery} />
              </span>
              <div className="h-8">
                <span className="text-sm font-semibold text-neutral-900">
                  {status.batteryCharge.value}
                  {status.batteryCharge.unit}
                </span>
                <div className="h-1 w-14 mt-1">
                  <ProgressBar value={status.batteryCharge.value} />
                </div>
              </div>
            </div>
          )}
          {status.mileage && (
            <div>
              <span className="text-xs text-neutral-500 block mb-1">
                <FormattedMessage {...messages.mileage} />
              </span>
              <div className="h-8">
                <span className="text-sm font-semibold text-neutral-900">
                  {status.mileage.value}
                  {status.mileage.unit}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  </div>
);
