import ReactMapGl, { Marker, NavigationControl } from 'react-map-gl';
import { useGeofencesQuery, Vehicle, VehicleStatus } from '@cooltra/api';
import { Tooltip } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { isBike } from '@cooltra/vehicle-utils';

import { Geofences, GoogleMapsLink, TimeAgo, VehicleIcon } from '~/common';
import { getEnvVariable } from '~/utils/environment';
import { isTestingEnv } from '~/utils/e2e';

import { TooltipItemLayout } from './TooltipItemLayout';
import messages from './messages';

export type GeolocationMapProps = {
  vehicle: Vehicle;
  status: VehicleStatus;
};

export const GeolocationMap = ({ vehicle, status }: GeolocationMapProps) => {
  const { formatMessage } = useIntl();
  const isVehicleABike = isBike({ telematics: vehicle.telematics });
  const vehicleType = isVehicleABike ? 'BIKE' : 'SCOOTER';
  const { data: geofences } = useGeofencesQuery({
    vehicleType,
  });

  const { operationalState, operationalConditions, bonusEnabled, model } =
    vehicle;
  const { geoLocation, speed, batteryCharge } = status;

  if (!geoLocation) {
    return <></>;
  }

  const { latitude, longitude, updatedAt } = geoLocation;

  const isTest = isTestingEnv();

  return (
    <ReactMapGl
      id="vehicle-geolocation-map"
      mapboxAccessToken={isTest ? '' : getEnvVariable('MAPBOX_ACCESS_TOKEN')}
      mapStyle={isTest ? '' : getEnvVariable('MAPBOX_STYLE')}
      initialViewState={{
        longitude,
        latitude,
        zoom: 12,
        bearing: 0,
        pitch: 0,
      }}
      style={{ width: '100%', height: '100%' }}
      scrollZoom
      attributionControl={false}
      testMode={isTest}
    >
      <NavigationControl showZoom showCompass={false} />
      <Geofences geofences={geofences} vehicleType={vehicleType} />
      <Marker latitude={latitude} longitude={longitude}>
        <Tooltip
          interactive
          button={
            <span className="flex justify-center w-12">
              <VehicleIcon
                operationalState={operationalState}
                operationalConditions={operationalConditions}
                bonusEnabled={bonusEnabled}
                model={model}
                batteryCharge={batteryCharge?.value}
              />
            </span>
          }
          content={
            <div className="px-5 py-4 flex flex-col w-72 gap-3">
              <TooltipItemLayout
                title={formatMessage(messages.geoLocation)}
                value={
                  <GoogleMapsLink latitude={latitude} longitude={longitude} />
                }
              />
              <TooltipItemLayout
                title={formatMessage(messages.speed)}
                value={
                  speed != null ? (
                    `${speed.value} ${speed.unit}`
                  ) : (
                    <span className="block text-xs font-normal text-neutral-300">
                      <FormattedMessage {...messages.noData} />
                    </span>
                  )
                }
              />
              <TooltipItemLayout
                title={formatMessage(messages.lastUpdated)}
                value={
                  updatedAt ? (
                    <p className="text-xs font-semibold">
                      <TimeAgo ISODate={updatedAt} />
                    </p>
                  ) : (
                    <span className="block text-xs font-normal text-neutral-300">
                      <FormattedMessage {...messages.noData} />
                    </span>
                  )
                }
              />
            </div>
          }
        />
      </Marker>
    </ReactMapGl>
  );
};
