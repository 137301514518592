import { languages } from '@cooltra/countries';
import { Divider } from '@cooltra/ui';
import { getKeys } from '@cooltra/utils';
import { useFormContext } from '@cooltra/form';

import { useAccordion } from '~/hooks';

import { PromotionStateType } from '../PromotionState/PromotionState';
import { PromotionFormValues } from '../PromotionForm';

import { TranslationSectionHeader } from './TranslationSectionHeader';
import { TranslationsSection } from './TranslationSection';

export const Translations = () => {
  const { getHeaderProps, getSectionProps, isActiveSection } = useAccordion([
    0,
  ]);
  const { values } = useFormContext<PromotionFormValues>();
  const { editMode, stateType } = values;
  const disabled = editMode && stateType === PromotionStateType.EXPIRED;

  return (
    <div>
      {getKeys(languages).map((language, index) => {
        const id = `${language}-accordion-item`;
        const { onClick, ...headerPropsRest } = getHeaderProps(id, index);
        const { ref: sectionRef, ...sectionPropsRest } = getSectionProps(
          id,
          index
        );
        const { key: headerKey, ...headerProps } = headerPropsRest;
        const { key: sectionKey, ...sectionProps } = sectionPropsRest;
        return (
          <div key={id}>
            <Divider />
            <TranslationSectionHeader
              isActive={isActiveSection(index)}
              language={language}
              onClick={onClick}
              key={headerKey}
              {...headerProps}
            />
            <TranslationsSection
              key={sectionKey}
              language={language}
              ref={sectionRef}
              disabled={disabled}
              {...sectionProps}
            />
          </div>
        );
      })}
    </div>
  );
};
