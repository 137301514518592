import { BatteryState } from '~/common';

import { StatusContainer } from './StatusContainer';

type BatteryProps = {
  batteryCharge: number | undefined;
};

export const Battery = ({ batteryCharge }: BatteryProps) => (
  <StatusContainer>
    {batteryCharge !== undefined && (
      <BatteryState value={batteryCharge} className="w-16" />
    )}
  </StatusContainer>
);
